import React from 'react';
export default function Logo() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='49' height='33' viewBox='0 0 49 33'>
      <g id='Logo_Ayzer' data-name='Logo Ayzer' transform='translate(-83.76 -35.978)'>
        <path
          id='Path_1'
          data-name='Path 1'
          d='M122.094,59.4c.145.148.333.081.5.081q4.778.007,9.556,0c.782,0,.651.045.478.638a12.377,12.377,0,0,1-2.517,4.675,11.951,11.951,0,0,1-5.178,3.5,12.377,12.377,0,0,1-2.731.6,12.061,12.061,0,0,1-2.149.05,13.079,13.079,0,0,1-2.626-.453,12.677,12.677,0,0,1-4.272-2.059,12.038,12.038,0,0,1-2.835-2.968,11.859,11.859,0,0,1-1.68-3.847,1.656,1.656,0,0,1-.031-.384q0-5.747,0-11.494c0-.112-.037-.235.036-.334.048-.064.1-.018.129.038.139,3.735.024,7.472.065,11.208,0,.222-.061.46.1.659a2.368,2.368,0,0,0,.7.038q6.011,0,12.022,0A.754.754,0,0,1,122.094,59.4Z'
          fill='#cce9ee'
        />
        <path
          id='Path_2'
          data-name='Path 2'
          d='M107.734,47.607c.031-.059.086-.1.127-.031a.677.677,0,0,1,.034.378q0,5.574.006,11.148a3.954,3.954,0,0,1-.217,1.134,11.3,11.3,0,0,1-1.705,3.516,12.369,12.369,0,0,1-3.963,3.525,13.578,13.578,0,0,1-4.1,1.471,11.731,11.731,0,0,1-3.189.194,12.147,12.147,0,0,1-6.506-2.419A11.816,11.816,0,0,1,84.44,61.7a13.279,13.279,0,0,1-.633-1.782,1.345,1.345,0,0,1-.045-.209c-.014-.137.044-.221.2-.223l.352,0q4.8,0,9.6,0c.168,0,.354.064.5-.076a.782.782,0,0,1,.469-.066q6.064.008,12.128,0a1.484,1.484,0,0,0,.563-.044.986.986,0,0,0,.1-.664q.006-4.919,0-9.838C107.667,48.4,107.734,48,107.734,47.607Z'
          fill='#cce9ee'
        />
        <path
          id='Path_3'
          data-name='Path 3'
          d='M107.724,59.484,96.6,59.49c-.557,0-1.114,0-1.671,0a1.17,1.17,0,0,1-.514-.084,11.762,11.762,0,0,1-5.43-7.862,11.445,11.445,0,0,1,.924-7.089c.2-.444.439-.875.679-1.3.148-.263.19-.261.416-.045.367.351.727.709,1.09,1.064q3.019,2.951,6.041,5.9c.093.091.147.3.361.2.264.136.4.4.6.594q4.156,4.044,8.294,8.106C107.535,59.114,107.767,59.219,107.724,59.484Z'
          fill='#00b0ab'
        />
        <path
          id='Path_4'
          data-name='Path 4'
          d='M122.094,59.4a1.1,1.1,0,0,1-.468.089q-6.42-.006-12.841-.007c-.043-.266.189-.37.334-.513q4.1-4.024,8.222-8.033c.245-.238.41-.538.676-.754.028.05.009.174.122.1a1.776,1.776,0,0,0,.254-.237q3.485-3.406,6.971-6.811c.111-.108.2-.255.395-.292a7.935,7.935,0,0,1,.865,1.577,12.193,12.193,0,0,1,1.038,4.061,11.365,11.365,0,0,1-2.722,8.338A12.24,12.24,0,0,1,122.094,59.4Z'
          fill='#00b0ab'
        />
        <path
          id='Path_5'
          data-name='Path 5'
          d='M107.724,59.484a1.278,1.278,0,0,0-.316-.353q-4.268-4.175-8.541-8.346a1.712,1.712,0,0,1-.374-.513,11.794,11.794,0,0,1,9.28-14.28.654.654,0,0,1,.045.377q0,1.958,0,3.916c0,.072,0,.144,0,.215,0,.152-.071.2-.222.175-.45-.061-.9-.1-1.351-.172a7.654,7.654,0,0,0-.877-.055,2.046,2.046,0,0,0-2.134,1.631,5.648,5.648,0,0,0-.046,2.137,6.991,6.991,0,0,0,.623,2.14,5.844,5.844,0,0,1,.478,2.259,14.673,14.673,0,0,0,.7,4.011,6.424,6.424,0,0,0,.26.638.621.621,0,0,0,1.174-.1,13.355,13.355,0,0,0,.329-1.424c.216-1.111.38-2.233.7-3.322.08-.275.185-.543.279-.814.115.1.069.242.069.366q0,5.576,0,11.151C107.8,59.246,107.853,59.386,107.724,59.484Z'
          fill='#00b6cd'
        />
        <path
          id='Path_6'
          data-name='Path 6'
          d='M118.017,50.186a1.512,1.512,0,0,1-.441.664q-4.228,4.12-8.444,8.251a1.43,1.43,0,0,0-.347.385c-.161-.137-.086-.321-.086-.482q-.007-4.357,0-8.715c0-.816,0-1.631,0-2.447,0-.136-.058-.289.076-.4a12.767,12.767,0,0,1,.8,2.9c.167.858.3,1.723.522,2.571.011.042.015.085.027.126.117.425.263.576.583.6.348.031.512-.086.71-.516a7.576,7.576,0,0,0,.434-1.352,16.126,16.126,0,0,0,.468-3.4,5.474,5.474,0,0,1,.526-2.162,6.939,6.939,0,0,0,.594-3.7,2.105,2.105,0,0,0-1.682-2.02,3.948,3.948,0,0,0-1.185-.014c-.525.052-1.046.131-1.569.2-.3.04-.322.032-.323-.283q-.006-2.067,0-4.133c0-.308.015-.316.331-.237a11.977,11.977,0,0,1,9.126,9.468A11.679,11.679,0,0,1,118.017,50.186Z'
          fill='#00b6cd'
        />
      </g>
    </svg>
  );
}
